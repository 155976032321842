import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/log",
          name: "orders-log",
          component: () => import("@/view/pages/dashboard-pages/Log.vue")
        },
        {
          path: "/admins",
          name: "admins",
          component: () => import("@/view/pages/dashboard-pages/Admins.vue")
        },
        {
          path: "/order-history-details",
          name: "order-history-details",
          component: () =>
            import("@/view/pages/dashboard-pages/OrderHistoryDetails.vue")
        },
        {
          path: "/all-prodcuts",
          name: "all-prodcuts",
          component: () =>
            import("@/view/pages/dashboard-pages/AllProducts.vue")
        },
        {
          path: "/business-lines",
          name: "business-lines",
          component: () =>
            import("@/view/pages/dashboard-pages/BusinessLines.vue")
        },
        {
          path: "/help-center/:id",
          name: "help-center",
          component: () => import("@/view/pages/helpcenter/chat.vue")
        },

        {
          path: "/level-bonuses",
          name: "level-bonuses",
          component: () =>
            import("@/view/pages/dashboard-pages/LevelBonuses.vue")
        },
        {
          path: "/notifications",
          name: "notifications",
          component: () =>
            import("@/view/pages/dashboard-pages/Notifications.vue")
        },
        {
          path: "/categories",
          name: "categories",
          component: () => import("@/view/pages/dashboard-pages/Categories.vue")
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/pages/dashboard-pages/Users.vue")
        },
        {
          path: "/payments",
          name: "payments",
          component: () => import("@/view/pages/dashboard-pages/Payments.vue")
        },
        {
          path: "/promo-code-promotions",
          name: "promo-code-promotions",
          component: () =>
            import("@/view/pages/dashboard-pages/PromoCodePromotions.vue")
        },
        {
          path: "/riders",
          name: "riders",
          component: () => import("@/view/pages/dashboard-pages/Riders.vue")
        },
        {
          path: "/vendor",
          name: "vendor",
          component: () => import("@/view/pages/dashboard-pages/Vendor.vue")
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/view/pages/dashboard-pages/Settings.vue")
        }
      ]
    },

    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
